import React, { useState } from "react";
import {
  CopiedIcon,
  CopiedIcon2,
  CopyIcon,
  ScanIcon,
  ShareIcon,
} from "../../assets/Icons/profileIcon";
import { useWindowSize } from "../../utils/Hooks/useWindowSize";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { toasterObj } from "../Pricing/jsonData";

const ProfileCard = ({
  profileImg,
  name,
  position,
  setIsModalOpen,
  setIsModalExcOpen,
  url,
  file,
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 720;
  const [copyStatus, setCopyStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000);
    toast(<CustomeMsg />, toasterObj);
  };

  const downloadVCF = (file) => {
    setIsLoading(true);
    const vcfFileUrl = file;
    const link = document.createElement("a");
    link.href = vcfFileUrl;
    link.setAttribute("download", `${name}.vcf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setTimeout(() => {
      setIsLoading(false);
    }, 750);
  };

  return (
    <div className="w-[580px] max_xl:w-[440px] bg-ButtonPHover p-6 max_sm:p-3 rounded-[30px] max_sm:rounded-[20px] h-max max_md:w-full flex flex-col max_md:flex-row max_sm:flex-col">
      <div>
        <div className="rounded-2xl max_md:w-[50%] max_sm:w-[45%]">
          <img
            src={profileImg}
            alt="Profile_image"
            className="rounded-2xl w-[100%] min-h-[500px] max_xl:min-h-[380px] max_md:min-h-[100px] max_sm:min-h-[100px]"
          />
        </div>
        <div className="mt-6 max_sm:mt-3">
          <h1 className="text-subHeadingW font-OutfitFont font-semibold text-white max_md:text-text3Xl max_sm:text-text2Xl">
            {name}
          </h1>
          <h4 className="text-textMedium font-OutfitFont font-normal text-white max_sm:text-textSmall">
            {position}
          </h4>
        </div>
      </div>
      <div className="mt-10 max_sm:mt-[20px] max_md:hidden max_sm:block">
        <div className="flex gap-2 items-center w-full">
          <button
            disabled={isLoading}
            className="bg-ButtonP w-[370px] max_sm:w-[calc(100%-140px)] rounded-lg py-[11px] text-center text-white cursor-pointer"
            onClick={() => {
              if (!isLoading) downloadVCF(file);
            }}
          >
            {isLoading ? (
              <LoadingIcon
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            ) : (
              "Save contect"
            )}
          </button>
          <div
            className="p-[11px] rounded-lg bg-ButtonP cursor-pointer max_sm:w-max"
            onClick={() => setIsModalExcOpen(true)}
          >
            <ShareIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
          <div className="p-[11px] rounded-lg bg-ButtonP cursor-pointer max_sm:w-max">
            <CopyToClipboard text={url} onCopy={onCopyText}>
              {copyStatus ? (
                <CopiedIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              ) : (
                <CopyIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div
            className="p-[11px] rounded-lg bg-ButtonP cursor-pointer max_sm:w-max"
            onClick={() => setIsModalOpen(true)}
          >
            <ScanIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
        </div>
      </div>
      <div className="hidden max_md:block max_sm:hidden">
        <div className="flex flex-col gap-3 items-end justify-end h-full">
          <div
            className="p-[11px] max_720:p-2 rounded-lg bg-ButtonP cursor-pointer max_md:w-max"
            onClick={() => setIsModalExcOpen(true)}
          >
            <ShareIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
          <div className="p-[11px] max_720:p-2 rounded-lg bg-ButtonP cursor-pointer max_md:w-max">
            <CopyToClipboard text={url} onCopy={onCopyText}>
              {copyStatus ? (
                <CopiedIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              ) : (
                <CopyIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div
            className="p-[11px] max_720:p-2 rounded-lg bg-ButtonP cursor-pointer max_md:w-max"
            onClick={() => setIsModalOpen(true)}
          >
            <ScanIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
          <button
            disabled={isLoading}
            className="bg-ButtonP w-[280px] max_720:w-[180px] rounded-lg py-[11px] text-center text-white cursor-pointer"
            onClick={() => {
              if (!isLoading) downloadVCF(file);
            }}
          >
            {isLoading ? (
              <LoadingIcon
                style={{
                  width: "18px",
                  height: "18px",
                }}
              />
            ) : (
              "Save contect"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;

export const CustomeMsg = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 720;
  return (
    <div className="w-max flex gap-2 max_800:w-[200px]">
      <div className="max_800:mt-[2px]">
        <CopiedIcon2
          style={{
            width: isMobileView ? "18" : "24",
            height: isMobileView ? "18" : "24",
          }}
        />
      </div>
      <p className="text-textSmall font-normal text-[#23B339] font-OutfitFont max_sm:text-textExtraSmall">
        Copied to clipboard
      </p>
    </div>
  );
};

const LoadingIcon = (props) => (
  <>
    <svg
      {...props}
      aria-hidden="true"
      class="inline w-8 h-8 text-gray-200 animate-spinner dark:text-gray-600 fill-blue-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="#ffffff"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="#1d1d1d"
      />
    </svg>
    <span class="sr-only">Loading...</span>
  </>
);
